import { useMemo } from 'react';

import { HighwayCode } from 'driving-theory-common';

import { highwayCode } from '~/data';

const { getAnnexes } = HighwayCode.utils;

export const useHighwayCodeData = () => {
  const data = highwayCode;
  return { data };
};

export const useHighwayCodeIntroductionData = () => {
  const { data } = useHighwayCodeData();
  const introduction = useMemo(() => {
    if (data) {
      return data.introduction;
    } else {
      return null;
    }
  }, [data]);
  return introduction;
};

export const useHighwayCodeAnnexesData = () => {
  const { data } = useHighwayCodeData();
  const annexes = useMemo(() => {
    if (data) {
      return getAnnexes(data);
    } else {
      return [];
    }
  }, [data]);
  return annexes;
};

export const useHighwayCodeRulesData = () => {
  const { data } = useHighwayCodeData();
  const rules = useMemo(() => {
    if (data) {
      return data.rules;
    } else {
      return [];
    }
  }, [data]);
  return rules;
};

export const useHighwayCodeRulesListData = () => {
  const { data } = useHighwayCodeData();
  const rules = useMemo(() => {
    if (data) {
      return data.rulesList;
    } else {
      return [];
    }
  }, [data]);
  return rules;
};

export const useHighwayCodeGuidesData = () => {
  const { data } = useHighwayCodeData();
  const guides = useMemo(() => {
    if (data) {
      return data.guides;
    } else {
      return [];
    }
  }, [data]);
  return guides;
};
