import { graphql, useStaticQuery } from 'gatsby';

type SiteConfig = {
  buildEnvironment: 'dev' | 'prod';
  enableHighwayCode: boolean;
  mfeDomains: {
    games: string;
  };
};

interface SiteMetadata {
  config: SiteConfig;
}

export const useSiteMetadata = (): SiteMetadata => {
  const { site } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            config {
              buildEnvironment
              enableHighwayCode
              mfeDomains {
                games
              }
            }
          }
        }
      }
    `,
  );
  return site.siteMetadata as SiteMetadata;
};
