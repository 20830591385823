import { HighwayCode } from 'driving-theory-common';
import _findIndex from 'lodash/findIndex';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../rootReducer';

type GuideReadState = { readPct: number };

export type ModalScreenProps =
  | {
      page: 'annex';
      annexSectionId: string;
    }
  | {
      page: 'rule';
      ruleNumber: number;
    }
  | {
      page: 'intro';
      targetAnchor: string;
    };

export type HighwayCodeState = {
  rulesRead: number[];
  annexSectionsRead: string[];
  introSectionsRead: string[];
  guidesReadState: { [key: string]: GuideReadState };
  modalScreenProps: ModalScreenProps | null;
  modalScreenPropsHistory: ModalScreenProps[];
};

const initialState: HighwayCodeState = {
  ...HighwayCode.state.initialState,
  modalScreenProps: null,
  modalScreenPropsHistory: [],
};

export const highwayCodeSlice = createSlice({
  name: 'highwayCode',
  initialState,
  reducers: {
    ...HighwayCode.state.highwayCodeActions,
    onShowModalScreen(state, action: PayloadAction<ModalScreenProps>) {
      if (state.modalScreenProps) {
        state.modalScreenPropsHistory.push({
          ...state.modalScreenProps,
        });
      }
      state.modalScreenProps = action.payload;
    },
    onHideModalScreen(state) {
      state.modalScreenProps = null;
      state.modalScreenPropsHistory = [];
    },
    onGoToHistoryItem(state, action: PayloadAction<ModalScreenProps>) {
      const lastHistoryIndex = _findIndex(
        [...state.modalScreenPropsHistory].reverse(),
        action.payload,
      );

      console.log({ lastHistoryIndex });

      state.modalScreenProps = action.payload;
      state.modalScreenPropsHistory = state.modalScreenPropsHistory.slice(
        0,
        state.modalScreenPropsHistory.length - lastHistoryIndex - 1,
      );
    },
  },
});

export const highwayCodeReducer = highwayCodeSlice.reducer;
// TODO - sort out typescript for these combined actions objects
export const highwayCodeActions = highwayCodeSlice.actions as any;

export const highwayCodeSelectors = {
  ...HighwayCode.state.highwayCodeSelectors,
  getModalScreenPropsHistory: (state: RootState) =>
    state.highwayCode.modalScreenPropsHistory,
  getModalScreenProps: (state: RootState) => state.highwayCode.modalScreenProps,
};
