import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../rootReducer';
import { appActions } from '../slices/appSlice';

export function useAppState() {
  const dispatch = useDispatch();
  const { uiDisplayModeAutomatic, uiDisplayMode } = useSelector(
    (state: RootState) => state.app,
  );

  const onToggleUIDisplayMode = useCallback(() => {
    dispatch(appActions.onSetUiDisplayModeAutomatic(!uiDisplayModeAutomatic));
    // tracker.logEvent(EventNames.PROFILE_SET_DISPLAY_MODE, {
    //   uiDisplayModeAutomatic: !uiDisplayModeAutomatic,
    //   uiDisplayMode,
    // });
  }, [dispatch, uiDisplayModeAutomatic, uiDisplayMode]);

  const onSetUIDisplayModeLight = useCallback(() => {
    dispatch(appActions.onSetUiDisplayMode('light'));
    // tracker.logEvent(EventNames.PROFILE_SET_DISPLAY_MODE, {
    //   uiDisplayModeAutomatic,
    //   uiDisplayMode,
    // });
  }, [dispatch, uiDisplayModeAutomatic, uiDisplayMode]);

  const onSetUIDisplayModeDark = useCallback(() => {
    dispatch(appActions.onSetUiDisplayMode('dark'));
    // tracker.logEvent(EventNames.PROFILE_SET_DISPLAY_MODE, {
    //   uiDisplayModeAutomatic,
    //   uiDisplayMode,
    // });
  }, [dispatch, uiDisplayModeAutomatic, uiDisplayMode]);

  return {
    uiDisplayModeAutomatic,
    uiDisplayMode,
    onToggleUIDisplayMode,
    onSetUIDisplayModeLight,
    onSetUIDisplayModeDark,
  };
}
