import { useEffect, useState } from 'react';
import { UIDisplayMode } from '~/theme';

export const useColorScheme = (): UIDisplayMode => {
  const [mode, setMode] = useState<UIDisplayMode>('dark');

  useEffect(() => {
    function listener(event: MediaQueryListEvent) {
      setMode(event.matches ? 'dark' : 'light');
    }
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', listener);
    return () => {
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', listener);
    };
  }, []);

  return mode;
};
