import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _sumBy from 'lodash/sumBy';

import { highwayCodeSelectors, highwayCodeActions } from '~/store';
import {
  getContentSectionCompositeId,
  getGenericSectionsProgress,
  getGenericSectionsReadProgressPct,
  getSectionRuleNumbers,
  getAllRuleNumbers,
  getItemsReadProgressPercentage,
  getAnnexes,
} from '../utils';
import { useHighwayCodeData } from './useHighwayCodeData';

export function useHighwayCodeReadState() {
  const { data } = useHighwayCodeData();
  const dispatch = useDispatch();

  /* State */
  const rulesRead = useSelector(highwayCodeSelectors.getRulesRead);
  const annexSectionsRead = useSelector(
    highwayCodeSelectors.getAnnexSectionsRead,
  );
  const introSectionsRead = useSelector(
    highwayCodeSelectors.getIntroSectionsRead,
  );
  const guidesReadState = useSelector(highwayCodeSelectors.getGuidesReadState);

  /* Actions */
  const onSetRulesRead = useCallback(
    (rules: number[]) => {
      dispatch(highwayCodeActions.onSetRulesRead({ rules }));
    },
    [dispatch],
  );
  const onSetRulesUnread = useCallback(
    (rules: number[]) => {
      dispatch(highwayCodeActions.onSetRulesUnread({ rules }));
    },
    [dispatch],
  );
  const onSetAnnexSectionRead = useCallback(
    (sectionId: string, contentSectionId: string) => {
      dispatch(
        highwayCodeActions.onSetAnnexSectionRead({
          sectionId: getContentSectionCompositeId(sectionId, contentSectionId),
        }),
      );
    },
    [dispatch],
  );
  const onSetAnnexSectionUnread = useCallback(
    (sectionId: string, contentSectionId: string) => {
      dispatch(
        highwayCodeActions.onSetAnnexSectionUnread({
          sectionId: getContentSectionCompositeId(sectionId, contentSectionId),
        }),
      );
    },
    [dispatch],
  );
  const onSetIntroSectionRead = useCallback(
    (sectionId: string, contentSectionId: string) => {
      dispatch(
        highwayCodeActions.onSetIntroSectionRead({
          sectionId: getContentSectionCompositeId(sectionId, contentSectionId),
        }),
      );
    },
    [dispatch],
  );
  const onSetIntroSectionUnread = useCallback(
    (sectionId: string, contentSectionId: string) => {
      dispatch(
        highwayCodeActions.onSetIntroSectionUnread({
          sectionId: getContentSectionCompositeId(sectionId, contentSectionId),
        }),
      );
    },
    [dispatch],
  );
  const onSetGuideReadState = useCallback(
    (guideId: string, readPct: number, prevReadPct: number) => {
      dispatch(
        highwayCodeActions.onSetGuideReadPercentage({
          guideId,
          readPct,
          prevReadPct,
        }),
      );
    },
    [dispatch],
  );

  /* Read Pct */
  const introSectionsReadProgress = useMemo(() => {
    if (data) {
      return getGenericSectionsProgress([data.introduction], introSectionsRead);
    }
    return {};
  }, [data, introSectionsRead]);
  const introSectionsReadProgressPct = useMemo(() => {
    if (data) {
      return getGenericSectionsReadProgressPct(
        [data.introduction],
        introSectionsRead,
      );
    }
    return 0;
  }, [data, introSectionsRead]);

  const annexSectionsReadProgress = useMemo(() => {
    if (data) {
      return getGenericSectionsProgress(getAnnexes(data), annexSectionsRead);
    }
    return {};
  }, [data, annexSectionsRead]);
  const annexSectionsReadProgressPct = useMemo(() => {
    if (data) {
      return getGenericSectionsReadProgressPct(
        getAnnexes(data),
        annexSectionsRead,
      );
    }
    return 0;
  }, [data, annexSectionsRead]);

  const ruleSectionsReadProgress = useMemo(() => {
    const progress: {
      [key: string]: {
        progressPct: number;
      };
    } = {};
    if (data) {
      data.rules.forEach((section) => {
        const ruleNumbers = getSectionRuleNumbers(section);
        progress[section.id] = {
          progressPct: getItemsReadProgressPercentage(ruleNumbers, rulesRead),
        };
      });
    }
    return progress;
  }, [rulesRead, data]);
  const rulesReadProgressPct = useMemo(() => {
    if (data) {
      const ruleNumbers = getAllRuleNumbers(data.rules);
      const progress = getItemsReadProgressPercentage(ruleNumbers, rulesRead);
      return progress;
    } else {
      return 0;
    }
  }, [rulesRead, data]);

  const guidesReadProgressPct = useMemo(() => {
    if (data && guidesReadState) {
      const numGuides = data.guides.length;
      const totalPercentage = _sumBy(Object.values(guidesReadState), 'readPct');
      return totalPercentage / numGuides;
    }
    return 0;
  }, [data, guidesReadState]);

  const totalProgress = useMemo(() => {
    const introProportion = introSectionsReadProgressPct * 0.05;
    const rulesProportion = rulesReadProgressPct * 0.45;
    const guidesProportion = guidesReadProgressPct * 0.25;
    const annexesProportion = annexSectionsReadProgressPct * 0.25;
    return (
      introProportion + rulesProportion + guidesProportion + annexesProportion
    );
  }, [
    introSectionsReadProgressPct,
    rulesReadProgressPct,
    annexSectionsReadProgressPct,
    guidesReadProgressPct,
  ]);

  return {
    introSectionsRead,
    introReadProgress: introSectionsReadProgress,
    introReadProgressPct: introSectionsReadProgressPct,
    rulesRead,
    ruleSectionsReadProgress,
    rulesReadProgressPct,
    annexSectionsRead,
    annexSectionsReadProgress,
    annexSectionsReadProgressPct,
    guidesReadState,
    guidesReadProgressPct,
    totalProgress,
    onSetRulesRead,
    onSetRulesUnread,
    onSetAnnexSectionRead,
    onSetAnnexSectionUnread,
    onSetIntroSectionRead,
    onSetIntroSectionUnread,
    onSetGuideReadState,
  };
}
