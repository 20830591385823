import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  highwayCodeActions,
  highwayCodeSelectors,
  ModalScreenProps,
} from '../slices';

export const useHighwayCodeModalScreen = () => {
  const dispatch = useDispatch();
  const modalScreenProps = useSelector(
    highwayCodeSelectors.getModalScreenProps,
  );
  const modalScreenPropsHistory = useSelector(
    highwayCodeSelectors.getModalScreenPropsHistory,
  );

  const showRuleModal = useCallback((ruleNumber: number) => {
    dispatch(
      highwayCodeActions.onShowModalScreen({
        page: 'rule',
        ruleNumber,
      }),
    );
  }, []);

  const showAnnexSectionModal = useCallback((annexSectionId: string) => {
    dispatch(
      highwayCodeActions.onShowModalScreen({
        page: 'annex',
        annexSectionId,
      }),
    );
  }, []);

  const showIntroModal = useCallback((targetAnchor: string) => {
    dispatch(
      highwayCodeActions.onShowModalScreen({
        page: 'intro',
        targetAnchor,
      }),
    );
  }, []);

  const goToHistoryItem = useCallback((props: ModalScreenProps) => {
    dispatch(highwayCodeActions.onGoToHistoryItem(props));
  }, []);

  const hideModal = useCallback(() => {
    dispatch(highwayCodeActions.onHideModalScreen());
  }, []);

  return {
    isModalVisible: !!modalScreenProps,
    modalScreenProps,
    modalScreenPropsHistory,
    showRuleModal,
    showAnnexSectionModal,
    showIntroModal,
    goToHistoryItem,
    hideModal,
  };
};
