import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UIDisplayMode } from '~/theme';

export type AppState = {
  uiDisplayModeAutomatic: boolean;
  uiDisplayMode: UIDisplayMode;
};

const initialAppState: AppState = {
  uiDisplayModeAutomatic: true,
  uiDisplayMode: 'dark',
};

export const appSlice = createSlice({
  name: 'app',
  initialState: initialAppState,
  reducers: {
    onSetUiDisplayModeAutomatic(state, action: PayloadAction<boolean>) {
      state.uiDisplayModeAutomatic = action.payload;
    },
    onSetUiDisplayMode(state, action: PayloadAction<UIDisplayMode>) {
      state.uiDisplayMode = action.payload;
    },
  },
});

export const appReducer = appSlice.reducer;
export const appActions = appSlice.actions;
