import { useMemo } from 'react';
import { SectionReadState } from 'driving-theory-common/dist/highway-code/types';

import {
  getRuleGroupReadState,
  getRuleGroupReadPercentage,
  getItemsReadProgressPercentage,
  getSectionRuleNumbers,
} from '../utils';
import { useHighwayCodeRulesData } from './useHighwayCodeData';
import { useHighwayCodeReadState } from './useHighwayCodeReadState';

export const useRuleSection = (ruleSectionId: string) => {
  const { rulesRead } = useHighwayCodeReadState();
  const rules = useHighwayCodeRulesData();

  const hasRuleSectionId = rules.map((r) => r.id).includes(ruleSectionId);
  const inViewSectionData = rules.filter((s) => s.id === ruleSectionId)[0];

  const ruleGroups = useMemo(() => {
    return hasRuleSectionId ? inViewSectionData.ruleGroups : [];
  }, [hasRuleSectionId, inViewSectionData]);

  const ruleGroupsReadProgress = useMemo(() => {
    const progress: {
      [key: string]: { readState: SectionReadState; readPct: number };
    } = {};
    ruleGroups.forEach((ruleGroup) => {
      progress[ruleGroup.id] = {
        readState: getRuleGroupReadState(ruleGroup, rulesRead),
        readPct: getRuleGroupReadPercentage(ruleGroup, rulesRead),
      };
    });
    return progress;
  }, [ruleGroups, rulesRead]);

  const sectionReadPct = useMemo(() => {
    if (inViewSectionData) {
      const ruleNumbers = getSectionRuleNumbers(inViewSectionData);
      return getItemsReadProgressPercentage(ruleNumbers, rulesRead);
    }
    return 0;
  }, [inViewSectionData, rulesRead]);

  return {
    title: hasRuleSectionId ? inViewSectionData.title : '',
    introduction: hasRuleSectionId ? inViewSectionData.introduction : '',
    href: hasRuleSectionId ? inViewSectionData.href : '',
    ruleGroups,
    ruleGroupsReadProgress,
    sectionReadPct,
  };
};
