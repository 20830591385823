import { theme as baseTheme } from 'driving-theory-common';
import type {
  Theme as BaseTheme,
  UIDisplayMode,
  Sections,
} from 'driving-theory-common/dist/theme';

export { UIDisplayMode, Sections };

export type Theme = BaseTheme & {
  shadowProps: any;
  buttonElevation: any[];
};

const buttonElevation = [];
const defaultButtonElevation = {
  // ios
  shadowOpacity: 0,
  shadowRadius: 2,
  shadowOffset: { height: 2, width: 0 },
  // android
  elevation: 0,
  margin: 4,
};
buttonElevation[0] = {
  ...defaultButtonElevation,
};
buttonElevation[1] = {
  ...defaultButtonElevation,
  shadowOpacity: 0.2,
  elevation: 1,
};
buttonElevation[2] = {
  ...defaultButtonElevation,
  shadowOpacity: 0.3,
  elevation: 2,
};

export const theme: Theme = {
  ...baseTheme,
  shadowProps: {
    // ios
    shadowOpacity: 0.1,
    shadowRadius: 2,
    shadowOffset: { height: 2, width: 0 },
    // android
    elevation: 1,
    margin: 4,
  },
  buttonElevation,
};
