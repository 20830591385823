import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { appReducer, highwayCodeReducer } from './slices';

const persistReducerProps: Pick<
  PersistConfig<any>,
  'storage' | 'writeFailHandler'
> = {
  storage,
  // writeFailHandler: (err) => logger.error('redux-persist writeFail', err),
};

const rootReducer = combineReducers({
  app: persistReducer(
    {
      ...persistReducerProps,
      key: 'app',
      version: 1,
    },
    appReducer,
  ),
  highwayCode: persistReducer(
    {
      ...persistReducerProps,
      key: 'highwayCode',
      version: 1,
      blacklist: ['modalScreenProps', 'modalScreenPropsHistory'],
    },
    highwayCodeReducer,
  ),
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
