import React, { createContext, useMemo } from 'react';
import _merge from 'lodash/merge';
import { ThemeProvider as SCThemeProvider } from 'styled-components';

import { useColorScheme } from '~/hooks';
import { useAppState } from '~/store/hooks';

import { theme as baseTheme, Theme, UIDisplayMode } from './theme';

interface IThemeContext {
  theme: Theme;
}

export const ThemeContext = createContext<IThemeContext>({
  theme: baseTheme,
});

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const { uiDisplayMode, uiDisplayModeAutomatic } = useAppState();
  // const tracker = useAnalyticsTracker();
  const colorScheme = useColorScheme();

  const displayMode = useMemo(() => {
    let mode: UIDisplayMode = 'light';
    if (!uiDisplayModeAutomatic) {
      mode = uiDisplayMode;
    } else {
      mode = colorScheme;
    }
    return mode;
  }, [colorScheme, uiDisplayMode, uiDisplayModeAutomatic]);

  const theme = useMemo(() => {
    const displayModeColors = _merge(
      {},
      baseTheme.colors,
      baseTheme.colorModes?.[displayMode] || {},
    );
    return {
      ...baseTheme,
      displayMode,
      colors: displayModeColors,
    };
  }, [displayMode]);

  // useEffect(() => {
  //   tracker.setContextParam({
  //     '72.displayMode': displayMode,
  //   });
  // }, [tracker, displayMode]);

  return (
    <ThemeContext.Provider value={{ theme }}>
      <SCThemeProvider theme={theme}>
        <>{children}</>
      </SCThemeProvider>
    </ThemeContext.Provider>
  );
}
