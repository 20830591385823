import React, { CSSProperties } from 'react';

import { useTheme } from './useTheme';
import { Theme } from './theme';

export interface Dimensions {
  window: { width: number; height: number };
}

export const useDimensions = (): Dimensions => {
  return {
    window: {
      width: 1000,
      height: 700,
    },
  };
};

type NamedStyles<T> = { [key in keyof T]: CSSProperties };

type StyleBuilder<T> = ({
  theme,
  dimensions,
}: {
  theme: Theme;
  dimensions: Dimensions;
}) => T;

export const useStyleSheet = <T>(
  styleBuilder: StyleBuilder<T | NamedStyles<T>>,
) => {
  const theme = useTheme();
  const dimensions = useDimensions();

  const styles: T = React.useMemo(
    () => styleBuilder({ theme, dimensions }) as T,
    [theme, dimensions],
  );

  return {
    theme,
    dimensions,
    styles,
  };
};

// A no-op method to support type guards with generics
// Types based on Stylesheet.create, to provide auto-complete in styles
export const createStylesheet = <T extends NamedStyles<T> | NamedStyles<any>>(
  styleBuilder: StyleBuilder<T | NamedStyles<T>>,
) => styleBuilder;
